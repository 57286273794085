import { useEffect, useRef } from 'react';
import client from 'smartlook-client';

const Smartlook = ({ isAuthenticated, user }) => {
  const identified = useRef(false);

  useEffect(() => {
    client.init('40cd23167b20361b946672d456a1151146ebdc24', { region: 'eu' });
  }, []);

  useEffect(() => {
    if (!client.initialized() || identified.current) {
      return;
    }

    if (isAuthenticated && user) {
      identified.current = client.identify(user.id, {
        name: [user.firstName, user.lastName].filter(Boolean).join(' '),
        email: user.email,
        client: user.client?.name,
      });
    }

    return () => {
      if (!isAuthenticated) {
        client.anonymize();
        identified.current = false;
      }
    };
  }, [isAuthenticated, user]);

  return null;
};

export default Smartlook;
